import React from 'react';
import { bool, string, func } from 'prop-types';
import { loadable } from 'nordic/lazy';
import { RecommendationsCarouselFree } from '@recommendations-fe/carousel-free';
import RecommendationsIconFactory from './icon-factory';

const RecommendationsCardVertical = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardVertical,
});

RecommendationsCardVertical.preload();

const Recommendations = ({
  isPolycard,
  thousandSeparator,
  decimalSeparator,
  runCatchErrorBoundary = () => {},
  ...props
}) => {
  try {
    return (
      <RecommendationsCarouselFree
        Card={RecommendationsCardVertical}
        IconFactory={RecommendationsIconFactory}
        contextProps={{
          type: 'grid-card',
          thousandSeparator,
          decimalSeparator,
        }}
        cardProps={{
          polyClassName: 'recos-polycard',
        }}
        isPolycard={isPolycard}
        paddingHorizontal={16}
        {...props}
        runCatchErrorBoundary={runCatchErrorBoundary}
      />
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  runCatchErrorBoundary: func,
};

export default Recommendations;
